#about {
  background: linear-gradient(
    181.05deg,
    rgba(67, 203, 255) 0.9%,
    rgba(151, 8, 204, 0.8) 98.64%
  );
  position: relative;
}
#about h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 69px;
  color: #fff;
  margin-bottom: 20px;
}

#about .about-main {
  background-image: url("/public/assets/img/bggim.png");
  background-size: 80%;
  position: relative;
  width: 100%;
}
#about label {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  margin-top: 10px;
}

.our-work-process {
  color: #fff;
  text-align: center;
}
.our-work-process .step-box-con {
  width: 240px;
  margin: auto;
}
.our-work-process .step-box {
  margin-left: 20px;
  margin-right: 20px;
}
.our-work-process .step-icon {
  margin: auto;
}
.our-work-process .step-icon img {
  width: 174px;
  height: 174px;
}
.our-work-process .VdoPlayer {
  margin-bottom: 60px;
}
.our-work-process .VdoPlayer img {
  width: 70%;
}

@media (min-device-width: 280px) and (max-device-width: 768px) {
  #about h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
  }

  #about .about-main {
    background-size: 120%;
  }
  #about label {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
  }
  .our-work-process .step-box {
    width: 150px;
    margin: auto;
  }
  .our-work-process .step-box-con {
    width: 130px;
    margin: auto;
  }
  .our-work-process .step-icon img {
    width: 92px;
    height: 92px;
  }
}
@media (min-width: 768px) and (max-width: 1180px){
  #about h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
  }

  #about .about-main {
    background-size: 120%;
  }
  #about label {
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
  }
  .our-work-process .step-box {
    width: 150px;
    margin: auto;
  }
  .our-work-process .step-box-con {
    width: 130px;
    margin: auto;
  }
  .our-work-process .step-icon img {
    width: 92px;
    height: 92px;
  }
}
#curveUpColor3 {
  position: absolute !important;
  left: 0 !important;
  width: calc(100% + 20vmin) !important;
  bottom: 0px !important;
  margin-left: -10vmin;
}
#curveUpColor3 path,
#curveDownColor3 path {
  fill: #fff;
  stroke: #fff;
}
