#banner-partner h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 52px;
  color: rgba(237, 57, 123, 1);
  margin-bottom: 40px;
}
#banner-partner .default-box {
  display: inline;
}
#banner-partner .slick-box {
  display: none;
}
@media (max-device-width: 420px) and (orientation:portrait) {
  #banner-partner h1 {
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
  }
  #banner-partner .default-box {
    display: none;
  }
  #banner-partner .slick-box {
    display: inline;
  }
}
@media (min-width: 768px) and (max-width: 1180px) {
  #banner-partner h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 35px;
  }
}
