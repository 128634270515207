#details-content h1 {
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 52px;
  color: #282e2f;
}
#details-content h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #71c8ef;
}
#details-content p {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 35px;
  color: #282e2f;
}
@media (min-device-width: 280px) and (max-device-width: 768px) {
  #details-content {
    padding: 20px;
  }
  #details-content h1 {
    font-size: 24px;
    line-height: 35px;
    text-align: center;
  }
  #details-content h2 {
    font-size: 18px;
    line-height: 26px;
    text-align: center;
  }
  #details-content p {
    font-size: 16px;
    line-height: 23px;
    text-align: center;
  }
}
@media (min-width: 768px) and (max-width: 1180px) {
  #details-content {
    padding: 20px;
  }
  #details-content h1 {
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
  }
  #details-content h2 {
    font-size: 18px;
    line-height: 26px;
  }
  #details-content p {
    font-size: 16px;
    line-height: 23px;
  }
}
