/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  /* height: 72px; */
  transition: all 0.5s;
  z-index: 997;
  transition: all 0.5s;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
#header .logo h1 {
  font-size: 30px;
  margin: 0;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 2px;
}
#header .logo h1 a,
#header .logo h1 a:hover {
  color: #7a6960;
  text-decoration: none;
}
#header .logo img,
.mobile-logo img {
  padding: 0;
  margin: 0;
  max-height: 70px;
}
#header.header-scrolled {
  height: 70px;
  transition: all 0.5s;
}

.mobile-logo {
  display: none !important;
}
.mobile-lang {
  display: none !important;
}
@media (max-width: 991px) {
  .mobile-logo {
    display: block !important;
  }
  .mobile-lang {
    display: block !important;
  }
}
