#download .txtRating {
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 65px;

  background: linear-gradient(
    180deg,
    #e70034 0%,
    #d70062 56.25%,
    #cb0084 99.48%,
    #ca0085 99.99%,
    #ca0086 100%,
    #ca0086 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
#download .starbox {
  margin-top: 20px;
}
#download .starbox img {
  width: 35px;
  height: 35px;
  margin-right: 8px;
}
#download .download-d-flex {
    display: flex;
}
@media (min-device-width: 280px) and (max-device-width: 768px) {
  #download .txtRating {
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
  }
  #download .starbox img {
    width: 25px;
    height: 25px;
    
  }
  #download .store-icon img {
    width: 222px;
    margin-bottom: 10px;
  }

}
@media (min-device-width: 280px) and (max-device-width: 768px) and (orientation:landscape){
  
  #download .store-icon img {
    width: 172px;
    margin-bottom: 10px;
  }

}
@media (min-width: 768px) and (max-width: 1180px) {

    #download .img-store-icon img {
        width: 160px;
        margin-bottom: 10px;
      }
    #download .txtRating {
        font-weight: 400;
        font-size: 24px;
        line-height: 35px;
      }
      #download .starbox img {
        width: 25px;
        height: 25px;
        
      }
}