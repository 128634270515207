#successful-businessman .header-txt {
  background: #ffebeb;
  padding: 20px;
  width: 100%;
}
#successful-businessman .header-txt h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 52px;
  color: rgba(237, 57, 123, 1);
}
#successful-businessman {
  margin-bottom: 50px;
}

#successful-businessman .data-box-img {
  text-align: center;
  background: #ffebeb;
}
#successful-businessman .data-box-img img {
  border-radius: 50%;
  width: 220px;
  height: 220px;
  margin: auto;
  display: block;
  padding: 10px;
}
#successful-businessman .data-box-desc {
  padding: 20px 20px 20px 20px;
  background: #ffebeb;
  min-height: 250px;
}
#successful-businessman .data-box-desc label {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: #ed397b;

  text-align: center;
}
#successful-businessman .data-box-name {
  padding: 10px 0 0 0;
}
#successful-businessman .data-box-name label {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;

  text-align: center;

  color: #ed397b;
}
#successful-businessman .data-box-income {
  padding: 10px 0 20px 0;
}
#successful-businessman .data-box-income label {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;

  text-align: center;

  color: #ed397b;
}

#successful-businessman .nextArrow,
.prevArrow {
  font-size: 50px;
  line-height: 0;
  position: absolute;
  top: 15%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;
  color: #ff5757;
}
#successful-businessman .prevArrow {
  left: 25px;
  z-index: 1;
}
[dir="rtl"] .prevArrow {
  right: -25px;
  left: auto;
}
#successful-businessman .nextArrow {
  right: 50px;
}
[dir="rtl"] .nextArrow {
  right: auto;
  left: -25px;
}
@media (min-device-width: 280px) and (max-device-width: 768px) {
  #successful-businessman .header-txt h1 {
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
  }
  #successful-businessman .data-box-desc label {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
  }
  #successful-businessman .data-box-img img {
    width: 99px;
    height: 99px;
  }
  #successful-businessman .data-box-name label {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
  }
  #successful-businessman .data-box-income label {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
  }
}
@media (min-width: 768px) and (max-width: 1180px) {
  #successful-businessman .header-txt h1 {
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
  }
  #successful-businessman .data-box-desc label {
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
  }
  #successful-businessman .data-box-img img {
    width: 113px;
    height: 113px;
  }
  #successful-businessman .data-box-name label {
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
  }
  #successful-businessman .data-box-income label {
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
  }
}
