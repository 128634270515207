#partner h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 52px;
  color: rgba(237, 57, 123, 1);
  margin-bottom: 40px;
}
#partner h2 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: #ed397b;
  margin-bottom: 40px;
}
#partner .partner-d-flex {
  display: flex;
}
#partner img {
    width: 229px;
    
}
@media (min-device-width: 280px) and (max-device-width: 768px) {
  #partner .partner-d-flex {
    display: contents;
  }
  #partner {
    padding: 20px;
  }
  #partner h1 {
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
  }
  #partner h2 {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 0px;
  }
  #partner img {
    width: 260px;
  }
}
@media (min-width: 768px) and (max-width: 1180px) {
  
  #partner h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 35px;
  }
  #partner h2 {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 0px;
  }
  #partner img {
    width: 201px;
  }
}