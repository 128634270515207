/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
  background: linear-gradient(0deg, #56575b, #56575b), #ffffff;
  padding: 0 0 30px 0;
  font-size: 14px;
}
.footer .footer-top {
  background-size: contain;

  padding: 30px 0 30px 0;
}
@media (max-width: 992px) {
  .footer .footer-top {
    background-position: center bottom;
  }
}
.footer .footer-top .footer-info {
  margin-bottom: 30px;
}
.footer .footer-top .footer-info .logo {
  line-height: 0;
  margin-bottom: 15px;
}
.footer .footer-top .footer-info .logo img {
  max-height: 40px;
  margin-right: 6px;
}
.footer .footer-top .footer-info .logo span {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #012970;
  font-family: "Nunito", sans-serif;
  margin-top: 3px;
}
.footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Nunito", sans-serif;
}
.footer .footer-top .social-links a {
  font-size: 20px;
  display: inline-block;
  color: rgba(1, 41, 112, 0.5);
  line-height: 0;
  margin-right: 10px;
  transition: 0.3s;
}
.footer .footer-top .social-links a:hover {
  color: #012970;
}
.footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  color: #012970;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 12px;
}
.footer .footer-top .footer-links {
  margin-bottom: 30px;
}
.footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #d0d4fc;
  font-size: 12px;
  line-height: 0;
}
.footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}
.footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}
.footer .footer-top .footer-links ul a {
  color: #013289;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}
.footer .footer-top .footer-links ul a:hover {
  color: #4154f1;
}
.footer .footer-top .footer-contact p {
  line-height: 26px;
}

.footer .copyright {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;

  color: rgba(255, 255, 255, 0.6);
}

/*--------------------------------------------------------------
# Footer Customs
--------------------------------------------------------------*/

.col-btn .start-btn-footer {
  float: right;
  margin-bottom: 20px;
}
.col-btn .start-btn-footer button:hover {
  background: #ffebef;
  color: red;
}
.col-btn .start-btn-footer button {
  border: 0;
  width: 237px;
  height: 48px;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.2);

  border-radius: 8px;

  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
}
.col-content .header-logo-text {
  margin-top: -60px;
}
.col-content .header-logo-text h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 52px;
  display: contents;
}
.col-content .header-logo-text-detail {
  margin-top: 20px;
}
.col-content .header-logo-text-detail p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;

  color: #ffffff;
}
.col-content h1,
h4 {
  color: #ffffff !important;
}
.col-content .footer-links a:hover {
  color: #e70034 !important;
}
.col-content .footer-links a {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: #ffffff !important;
}
.col-content .footer-links h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 35px;
}
.store-icon-box {
  padding: 0;
  margin: 0 10px 10px 10px;

  width: 209px;
}
.store-icon img {
  /* margin: 5px; */
  width: 100%;
}
.store-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.col-content .header-logo-text img {
  width: 119px;
  height: 119px;
}

@media (max-device-width: 1180px) {
  .col-content .header-logo-text h1{
    font-size: 26px;
  }
}
@media (min-device-width: 280px) and (max-device-width: 920px) {
  .footer .footer-top {
    padding: 30px 0 0 0;
  }
  .footer {
    text-align: center !important;
  }
  .footer .footer-top .footer-links ul li {
    justify-content: center;
  }
  .col-content .header-logo-text {
    margin-bottom: 30px;
  }
  .col-content .header-logo-text h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
  }
  .col-content .header-logo-text img {
    width: 80px;
    height: 80px;
  }
  .col-content {
    margin-top: 50px;
  }
  .col-content .header-logo-text-detail p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 20px;
  }
  .footer .copyright {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
  }
  .footer .footer-top h4 {
    padding-bottom: 0;
  }
  .col-content .footer-links h4 {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
  }
  .footer .footer-top .footer-links ul a {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
  }
  .footer .footer-top .footer-links ul li {
    padding: 0;
  }
  .col-btn .start-btn-footer {
    float: none;
  }
  .col-content .header-logo-text-detail {
    margin: auto;
  }
}
