/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  margin-top: 72px;
  width: 100%;
  /* height: 100%; */

  background-size: cover;
  position: relative;
}
@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}
#hero:before {
  content: "";
  background: linear-gradient(174.83deg, #fff8b7 2.34%, #ed397b 93.36%);
  /* background: linear-gradient(180deg, #DB2457 0%, #B51977 100%); */
  /* background: linear-gradient(360deg, #E70034 0%, #D70062 56.25%, #CB0084 99.48%, #CA0085 99.99%, #CA0086 100%, #CA0086 100%); */

  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
#hero .hero-container {
  margin-top: 50px;
  margin-bottom: 50px;
  height: max-content !important;
  position: relative;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
#hero h1 {
  margin: 30px 0 50px 0;

  text-transform: uppercase;

  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 52px;
  /* color: rgba(203, 0, 132, 1); */
  background: linear-gradient(
    180deg,
    #e70034 0%,
    #d70062 56.25%,
    #cb0084 99.48%,
    #ca0085 99.99%,
    #ca0086 100%,
    #ca0086 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
#hero h2 {
  margin-bottom: 50px;

  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  text-align: center;

  background: linear-gradient(
    180deg,
    #e70034 0%,
    #d70062 56.25%,
    #cb0084 99.48%,
    #ca0085 99.99%,
    #ca0086 100%,
    #ca0086 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
@media (max-device-width: 395px){
  #hero h1 {
    
    font-size: 22px !important;
   
  }
}
@media (min-device-width: 280px) and (max-device-width: 920px){
  /* #hero{
    margin-top: 70;
  } */
  #hero h1 {
    margin: 0 0 10px 0;
    font-size: 24px;
    line-height: 35px;
  }
  #hero h2 {
    padding: 20px;
    font-size: 16px;
    line-height: 23px;
  
  }

  #hero .btn-start {
    font-size: 18px !important;
    line-height: 26px !important;
    width: 209px !important;
    height: 45px !important;
  }
}
@media (min-width: 768px) and (max-width: 1180px){
  #hero h1 {
    margin: 0 0 10px 0;
    font-weight: 400;
    font-size: 32px;
    line-height: 52px;
  }
  #hero h2 {
    padding: 20px;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
  }

  #hero .btn-start {
    font-size: 18px;
    line-height: 26px;
  }
}

#hero .btn-get-started {
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 28px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px;
  border: 2px solid #fff;
  color: #fff;
}
#hero .btn-get-started:hover {
  background: #2dc997;
  border: 2px solid #2dc997;
}

#hero .btn-start {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 35px;
  color: rgba(231, 0, 52, 1);

  width: 332px;
  height: 64px;
  background: #ffebeb;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

#hero .hero-container .img-box {
  margin-top: 50px;
  /* margin-bottom: 20px; */
}
#hero .hero-container img {
  width: 100%;
}
#hero .hero-container .btn-box {
  margin-bottom: 20px;
}

#stars {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 1313px 1929px #fff, 267px 1218px #fff, 16px 838px #fff,
    1622px 1184px #fff, 621px 247px #fff, 165px 485px #fff, 979px 149px #fff,
    1512px 665px #fff, 305px 243px #fff, 300px 74px #fff, 1920px 1654px #fff,
    798px 356px #fff, 1217px 117px #fff, 1422px 381px #fff, 1055px 1723px #fff,
    585px 380px #fff, 1758px 359px #fff, 160px 1529px #fff, 1250px 1511px #fff,
    860px 804px #fff, 340px 242px #fff, 544px 813px #fff, 845px 1641px #fff,
    292px 1440px #fff, 628px 1269px #fff, 841px 360px #fff, 1985px 1113px #fff,
    198px 1375px #fff, 1481px 241px #fff, 300px 784px #fff, 616px 1771px #fff,
    231px 567px #fff, 458px 562px #fff, 636px 1537px #fff, 1285px 1659px #fff,
    660px 1948px #fff, 1739px 249px #fff, 336px 461px #fff, 765px 645px #fff,
    1430px 550px #fff, 1721px 1717px #fff, 1044px 91px #fff, 702px 970px #fff,
    498px 1605px #fff, 1787px 1158px #fff, 947px 1656px #fff, 144px 1943px #fff,
    14px 1862px #fff, 932px 1978px #fff, 1958px 1584px #fff, 641px 549px #fff,
    157px 1782px #fff, 653px 200px #fff, 1699px 1187px #fff, 428px 967px #fff,
    1306px 1879px #fff, 912px 1101px #fff, 1587px 375px #fff, 675px 1881px #fff,
    793px 1332px #fff, 89px 942px #fff, 253px 1957px #fff, 1228px 331px #fff,
    1240px 225px #fff, 1358px 1354px #fff, 975px 948px #fff, 1622px 1992px #fff,
    181px 1155px #fff, 1118px 1968px #fff, 406px 1738px #fff, 784px 874px #fff,
    601px 1525px #fff, 1489px 1811px #fff, 758px 1975px #fff, 1643px 1692px #fff,
    572px 268px #fff, 1799px 1468px #fff, 1406px 475px #fff, 619px 1882px #fff,
    590px 427px #fff, 1058px 1723px #fff, 352px 874px #fff, 589px 1171px #fff,
    987px 1461px #fff, 1119px 718px #fff, 1941px 555px #fff, 324px 121px #fff,
    509px 786px #fff, 614px 1472px #fff, 924px 465px #fff, 1628px 1169px #fff,
    1771px 681px #fff, 71px 89px #fff, 391px 1130px #fff, 1657px 822px #fff,
    101px 1871px #fff, 1431px 771px #fff, 1926px 864px #fff, 1653px 269px #fff,
    970px 876px #fff, 1380px 714px #fff, 1346px 1402px #fff, 1021px 501px #fff,
    510px 337px #fff, 1258px 60px #fff, 1034px 239px #fff, 1481px 924px #fff,
    1277px 1579px #fff, 1775px 1306px #fff, 1171px 186px #fff,
    1760px 1821px #fff, 1129px 314px #fff, 867px 127px #fff, 1349px 477px #fff,
    715px 30px #fff, 1291px 1631px #fff, 1458px 865px #fff, 707px 1011px #fff,
    584px 646px #fff, 860px 577px #fff, 1154px 695px #fff, 162px 541px #fff,
    428px 846px #fff, 255px 143px #fff, 548px 496px #fff, 874px 1813px #fff,
    895px 885px #fff, 1328px 177px #fff, 1785px 1564px #fff, 144px 945px #fff,
    1050px 822px #fff, 1943px 846px #fff, 1690px 161px #fff, 1731px 302px #fff,
    455px 1263px #fff, 924px 1072px #fff, 1854px 559px #fff, 1824px 1989px #fff,
    629px 1273px #fff, 809px 1148px #fff, 66px 174px #fff, 446px 360px #fff,
    1459px 240px #fff, 623px 1530px #fff, 741px 1882px #fff, 467px 211px #fff,
    829px 395px #fff, 931px 62px #fff, 1846px 1435px #fff, 638px 1904px #fff,
    1460px 1114px #fff, 1984px 1710px #fff, 1114px 1879px #fff, 38px 1161px #fff,
    423px 603px #fff, 967px 1643px #fff, 1888px 603px #fff, 758px 1943px #fff,
    1580px 1274px #fff, 912px 957px #fff, 539px 962px #fff, 368px 1347px #fff,
    1409px 1580px #fff, 437px 1172px #fff, 1804px 755px #fff, 1529px 1068px #fff,
    25px 101px #fff, 1804px 445px #fff, 1357px 1754px #fff, 1619px 1272px #fff,
    372px 539px #fff, 1526px 505px #fff, 554px 522px #fff, 1005px 32px #fff,
    905px 474px #fff, 1223px 1808px #fff, 633px 569px #fff, 1959px 1716px #fff,
    494px 1323px #fff, 625px 1862px #fff, 1614px 1651px #fff, 1697px 1264px #fff,
    140px 565px #fff, 398px 81px #fff, 1066px 1971px #fff, 1007px 1000px #fff,
    1868px 477px #fff, 1012px 1843px #fff, 707px 331px #fff, 1563px 631px #fff,
    1122px 1578px #fff, 164px 73px #fff, 118px 477px #fff, 273px 1898px #fff,
    1247px 201px #fff, 473px 763px #fff, 574px 1601px #fff, 1589px 1877px #fff,
    655px 551px #fff, 924px 537px #fff, 1358px 273px #fff, 1302px 129px #fff,
    155px 1148px #fff, 664px 1944px #fff, 1773px 1888px #fff, 1816px 1727px #fff,
    1708px 1470px #fff, 114px 1558px #fff, 164px 577px #fff, 1109px 1287px #fff,
    1122px 1770px #fff, 852px 1901px #fff, 1532px 1349px #fff, 488px 72px #fff,
    529px 1182px #fff, 810px 79px #fff, 1800px 110px #fff, 440px 1684px #fff,
    1061px 1923px #fff, 1356px 449px #fff, 1002px 1824px #fff,
    1927px 1975px #fff, 893px 1536px #fff, 344px 902px #fff, 1108px 1781px #fff,
    50px 1560px #fff, 745px 399px #fff, 1347px 920px #fff, 891px 1725px #fff,
    825px 1219px #fff, 1085px 1095px #fff, 477px 51px #fff, 976px 1139px #fff,
    614px 1089px #fff, 1787px 327px #fff, 703px 53px #fff, 1375px 1861px #fff,
    1888px 260px #fff, 746px 819px #fff, 1230px 1365px #fff, 1987px 1535px #fff,
    107px 136px #fff, 500px 1804px #fff, 716px 1885px #fff, 1125px 980px #fff,
    1228px 1089px #fff, 1857px 1045px #fff, 191px 1898px #fff, 1703px 136px #fff,
    1317px 1024px #fff, 1502px 1683px #fff, 884px 531px #fff, 1283px 925px #fff,
    1733px 1673px #fff, 1471px 732px #fff, 392px 676px #fff, 1516px 1687px #fff,
    940px 888px #fff, 1568px 1204px #fff, 1556px 286px #fff, 1956px 1922px #fff,
    73px 1754px #fff, 1838px 1976px #fff, 1965px 1902px #fff, 321px 1754px #fff,
    1861px 1938px #fff, 831px 367px #fff, 1215px 992px #fff, 345px 1055px #fff,
    866px 1398px #fff, 1893px 883px #fff, 223px 1303px #fff, 298px 632px #fff,
    58px 892px #fff, 1460px 184px #fff, 1685px 1049px #fff, 1151px 882px #fff,
    901px 411px #fff, 1639px 674px #fff, 691px 893px #fff, 1991px 1191px #fff,
    1932px 1355px #fff, 1662px 802px #fff, 1992px 222px #fff, 1999px 1226px #fff,
    658px 1627px #fff, 966px 1666px #fff, 632px 65px #fff, 1386px 1736px #fff,
    197px 912px #fff, 607px 1554px #fff, 1281px 1987px #fff, 358px 903px #fff,
    92px 1172px #fff, 1489px 1219px #fff, 1499px 963px #fff, 1375px 1123px #fff,
    1253px 1306px #fff, 1427px 471px #fff, 350px 134px #fff, 370px 1456px #fff,
    1858px 1869px #fff, 1769px 539px #fff, 1154px 63px #fff, 1527px 689px #fff,
    1326px 1743px #fff, 340px 1535px #fff, 533px 683px #fff, 795px 1221px #fff,
    177px 1933px #fff, 856px 842px #fff, 1990px 850px #fff, 714px 1330px #fff,
    747px 1838px #fff, 4px 770px #fff, 873px 1196px #fff, 1084px 747px #fff,
    1567px 100px #fff, 722px 1890px #fff, 332px 1443px #fff, 1667px 994px #fff,
    453px 684px #fff, 1439px 66px #fff, 822px 680px #fff, 460px 1890px #fff,
    911px 1644px #fff, 69px 1360px #fff, 1465px 123px #fff, 285px 847px #fff,
    1311px 1886px #fff, 111px 14px #fff, 1432px 927px #fff, 795px 879px #fff,
    369px 1567px #fff, 1092px 809px #fff, 1470px 562px #fff, 713px 439px #fff,
    1028px 185px #fff, 1552px 329px #fff, 1346px 1114px #fff, 863px 1679px #fff,
    284px 1518px #fff, 463px 1475px #fff, 812px 1981px #fff, 1832px 1160px #fff,
    977px 1083px #fff, 837px 1388px #fff, 1367px 1518px #fff, 1953px 658px #fff,
    1361px 1699px #fff, 1595px 1782px #fff, 1002px 1645px #fff,
    1542px 1214px #fff, 1915px 516px #fff, 497px 404px #fff, 1405px 491px #fff,
    909px 1425px #fff, 1479px 1865px #fff, 176px 595px #fff, 977px 1067px #fff,
    1888px 930px #fff, 698px 411px #fff, 969px 1923px #fff, 368px 126px #fff,
    6px 839px #fff, 1390px 1814px #fff, 1303px 474px #fff, 1876px 959px #fff,
    1539px 1680px #fff, 1364px 1308px #fff, 1193px 738px #fff, 907px 1786px #fff,
    690px 1887px #fff, 1422px 1801px #fff, 737px 1982px #fff, 1692px 1582px #fff,
    448px 1640px #fff, 1716px 1572px #fff, 125px 1527px #fff, 414px 1908px #fff,
    242px 27px #fff, 936px 795px #fff, 1350px 1205px #fff, 1828px 1998px #fff,
    335px 115px #fff, 698px 82px #fff, 1442px 1994px #fff, 1596px 1329px #fff,
    485px 362px #fff, 676px 171px #fff, 1822px 45px #fff, 801px 1314px #fff,
    1295px 1724px #fff, 27px 975px #fff, 1179px 1850px #fff, 1342px 382px #fff,
    1120px 1080px #fff, 655px 537px #fff, 565px 1858px #fff, 839px 664px #fff,
    1214px 1777px #fff, 382px 795px #fff, 838px 1106px #fff, 1095px 939px #fff,
    1659px 169px #fff, 1988px 1596px #fff, 754px 1874px #fff, 314px 1180px #fff,
    1164px 140px #fff, 443px 907px #fff, 473px 1966px #fff, 652px 551px #fff,
    1671px 1932px #fff, 942px 969px #fff, 1520px 81px #fff, 1205px 538px #fff,
    1432px 1746px #fff, 961px 1583px #fff, 711px 1031px #fff, 195px 1787px #fff,
    1762px 254px #fff, 8px 911px #fff, 1497px 1719px #fff, 203px 880px #fff,
    234px 1568px #fff, 1935px 466px #fff, 1949px 410px #fff, 455px 194px #fff,
    1262px 682px #fff, 976px 1701px #fff, 7px 978px #fff, 1092px 950px #fff,
    1967px 425px #fff, 91px 1740px #fff, 544px 1914px #fff, 154px 1793px #fff,
    441px 1588px #fff, 1614px 988px #fff, 597px 904px #fff, 1949px 1911px #fff,
    1000px 1380px #fff, 518px 1673px #fff, 1656px 1734px #fff,
    1975px 1785px #fff, 315px 1808px #fff, 1124px 746px #fff, 934px 596px #fff,
    1249px 1325px #fff, 927px 1136px #fff, 1190px 426px #fff, 658px 528px #fff,
    953px 308px #fff, 376px 1230px #fff, 1303px 1369px #fff, 1064px 1112px #fff,
    448px 446px #fff, 1610px 741px #fff, 1946px 155px #fff, 122px 739px #fff,
    1126px 197px #fff, 742px 1994px #fff, 1215px 1971px #fff, 1152px 1081px #fff,
    214px 1154px #fff, 1453px 1600px #fff, 1618px 1770px #fff, 783px 1955px #fff,
    1737px 272px #fff, 391px 773px #fff, 150px 745px #fff, 1842px 482px #fff,
    24px 732px #fff, 390px 1519px #fff, 662px 1442px #fff, 1270px 8px #fff,
    1515px 289px #fff, 536px 873px #fff, 1918px 202px #fff, 1840px 1490px #fff,
    582px 838px #fff, 1676px 406px #fff, 1070px 952px #fff, 944px 1561px #fff,
    977px 612px #fff, 1655px 361px #fff, 1015px 988px #fff, 181px 41px #fff,
    279px 923px #fff, 828px 252px #fff, 1188px 489px #fff, 1892px 929px #fff,
    705px 1273px #fff, 1534px 832px #fff, 1957px 1631px #fff, 646px 973px #fff,
    172px 1653px #fff, 1039px 1830px #fff, 96px 1176px #fff, 521px 352px #fff,
    1441px 181px #fff, 248px 1605px #fff, 159px 554px #fff, 1240px 1718px #fff,
    408px 689px #fff, 1433px 404px #fff, 760px 184px #fff, 863px 178px #fff,
    1605px 273px #fff, 1980px 1040px #fff, 1414px 998px #fff, 962px 1593px #fff,
    1069px 1049px #fff, 851px 1766px #fff, 926px 1046px #fff, 906px 536px #fff,
    970px 1534px #fff, 505px 662px #fff, 188px 1041px #fff, 1066px 926px #fff,
    573px 45px #fff, 1419px 1005px #fff, 216px 1045px #fff, 1702px 549px #fff,
    1935px 1067px #fff, 347px 1494px #fff, 910px 575px #fff, 1641px 1255px #fff,
    579px 1091px #fff, 1151px 334px #fff, 1438px 1952px #fff, 312px 1255px #fff,
    306px 911px #fff, 1794px 276px #fff, 1928px 493px #fff, 895px 1363px #fff,
    1808px 1070px #fff, 580px 1736px #fff, 311px 1688px #fff, 1873px 945px #fff,
    967px 1382px #fff, 1732px 332px #fff, 227px 888px #fff, 521px 744px #fff,
    661px 1754px #fff, 1138px 1225px #fff, 1427px 1095px #fff, 1670px 816px #fff,
    278px 1641px #fff, 1081px 1002px #fff, 380px 1794px #fff, 67px 302px #fff,
    1920px 191px #fff, 176px 1074px #fff, 205px 1870px #fff, 1457px 1339px #fff,
    390px 783px #fff, 1881px 1541px #fff, 1162px 1709px #fff, 1200px 861px #fff,
    316px 1913px #fff, 252px 168px #fff, 908px 1210px #fff, 679px 315px #fff,
    800px 547px #fff, 1709px 1411px #fff, 1786px 166px #fff, 919px 213px #fff,
    127px 51px #fff, 324px 542px #fff, 1939px 1565px #fff, 1907px 1145px #fff,
    177px 794px #fff, 110px 1618px #fff, 483px 539px #fff, 1894px 438px #fff,
    1051px 986px #fff, 1973px 1150px #fff, 902px 1697px #fff, 1485px 1475px #fff,
    1907px 1579px #fff, 1642px 1146px #fff, 1098px 741px #fff, 681px 1709px #fff,
    618px 238px #fff, 1066px 1973px #fff, 478px 1675px #fff, 1603px 1661px #fff,
    103px 1133px #fff, 1034px 435px #fff, 393px 398px #fff, 1771px 1397px #fff,
    1738px 522px #fff, 1178px 1807px #fff, 38px 532px #fff, 652px 1537px #fff,
    1610px 1184px #fff, 1355px 98px #fff, 1779px 456px #fff, 1413px 572px #fff,
    1993px 109px #fff, 157px 515px #fff, 1451px 635px #fff, 1984px 1318px #fff,
    348px 256px #fff, 1216px 863px #fff, 1468px 135px #fff, 1130px 1119px #fff,
    1523px 51px #fff, 1984px 487px #fff, 718px 1722px #fff, 1166px 1379px #fff,
    1495px 1832px #fff, 699px 1301px #fff, 1020px 522px #fff, 1716px 1554px #fff,
    141px 500px #fff, 1369px 1176px #fff, 1373px 1431px #fff, 630px 1760px #fff,
    849px 45px #fff, 774px 857px #fff, 1780px 1798px #fff, 90px 1962px #fff,
    130px 368px #fff, 1380px 234px #fff, 1566px 1838px #fff, 969px 263px #fff,
    246px 26px #fff, 108px 697px #fff, 206px 1282px #fff, 236px 726px #fff,
    1700px 332px #fff, 342px 1121px #fff, 706px 1671px #fff, 1559px 854px #fff,
    604px 1467px #fff, 1937px 245px #fff, 1938px 1345px #fff, 1265px 273px #fff,
    766px 1411px #fff, 1543px 1511px #fff, 864px 750px #fff, 173px 1961px #fff,
    1085px 177px #fff, 1680px 68px #fff, 1563px 1800px #fff, 1509px 1214px #fff,
    1113px 1115px #fff, 1825px 564px #fff, 47px 1455px #fff, 1907px 447px #fff,
    659px 562px #fff, 991px 1944px #fff, 1829px 92px #fff, 1960px 591px #fff,
    1708px 1471px #fff, 780px 449px #fff, 602px 1651px #fff, 1154px 1082px #fff,
    1809px 479px #fff, 1833px 479px #fff, 12px 257px #fff, 446px 725px #fff,
    1174px 279px #fff, 1228px 309px #fff, 209px 234px #fff, 1364px 186px #fff,
    1816px 418px #fff, 1577px 883px #fff, 185px 987px #fff, 1045px 341px #fff,
    1884px 1774px #fff, 130px 50px #fff, 1415px 237px #fff, 1028px 1386px #fff,
    904px 1884px #fff, 1062px 113px #fff, 1850px 948px #fff, 311px 1107px #fff,
    84px 545px #fff, 1217px 220px #fff, 1579px 190px #fff, 1594px 1673px #fff,
    577px 17px #fff, 387px 1275px #fff, 281px 963px #fff, 1032px 1353px #fff,
    1075px 1247px #fff, 173px 931px #fff, 110px 949px #fff, 229px 882px #fff,
    349px 1040px #fff, 944px 1916px #fff, 1678px 1668px #fff, 741px 1778px #fff,
    1150px 432px #fff, 1496px 1920px #fff, 134px 1014px #fff, 1468px 994px #fff,
    1080px 597px #fff;
  animation: animStar 50s linear infinite;
}
#stars:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 1313px 1929px #fff, 267px 1218px #fff, 16px 838px #fff,
    1622px 1184px #fff, 621px 247px #fff, 165px 485px #fff, 979px 149px #fff,
    1512px 665px #fff, 305px 243px #fff, 300px 74px #fff, 1920px 1654px #fff,
    798px 356px #fff, 1217px 117px #fff, 1422px 381px #fff, 1055px 1723px #fff,
    585px 380px #fff, 1758px 359px #fff, 160px 1529px #fff, 1250px 1511px #fff,
    860px 804px #fff, 340px 242px #fff, 544px 813px #fff, 845px 1641px #fff,
    292px 1440px #fff, 628px 1269px #fff, 841px 360px #fff, 1985px 1113px #fff,
    198px 1375px #fff, 1481px 241px #fff, 300px 784px #fff, 616px 1771px #fff,
    231px 567px #fff, 458px 562px #fff, 636px 1537px #fff, 1285px 1659px #fff,
    660px 1948px #fff, 1739px 249px #fff, 336px 461px #fff, 765px 645px #fff,
    1430px 550px #fff, 1721px 1717px #fff, 1044px 91px #fff, 702px 970px #fff,
    498px 1605px #fff, 1787px 1158px #fff, 947px 1656px #fff, 144px 1943px #fff,
    14px 1862px #fff, 932px 1978px #fff, 1958px 1584px #fff, 641px 549px #fff,
    157px 1782px #fff, 653px 200px #fff, 1699px 1187px #fff, 428px 967px #fff,
    1306px 1879px #fff, 912px 1101px #fff, 1587px 375px #fff, 675px 1881px #fff,
    793px 1332px #fff, 89px 942px #fff, 253px 1957px #fff, 1228px 331px #fff,
    1240px 225px #fff, 1358px 1354px #fff, 975px 948px #fff, 1622px 1992px #fff,
    181px 1155px #fff, 1118px 1968px #fff, 406px 1738px #fff, 784px 874px #fff,
    601px 1525px #fff, 1489px 1811px #fff, 758px 1975px #fff, 1643px 1692px #fff,
    572px 268px #fff, 1799px 1468px #fff, 1406px 475px #fff, 619px 1882px #fff,
    590px 427px #fff, 1058px 1723px #fff, 352px 874px #fff, 589px 1171px #fff,
    987px 1461px #fff, 1119px 718px #fff, 1941px 555px #fff, 324px 121px #fff,
    509px 786px #fff, 614px 1472px #fff, 924px 465px #fff, 1628px 1169px #fff,
    1771px 681px #fff, 71px 89px #fff, 391px 1130px #fff, 1657px 822px #fff,
    101px 1871px #fff, 1431px 771px #fff, 1926px 864px #fff, 1653px 269px #fff,
    970px 876px #fff, 1380px 714px #fff, 1346px 1402px #fff, 1021px 501px #fff,
    510px 337px #fff, 1258px 60px #fff, 1034px 239px #fff, 1481px 924px #fff,
    1277px 1579px #fff, 1775px 1306px #fff, 1171px 186px #fff,
    1760px 1821px #fff, 1129px 314px #fff, 867px 127px #fff, 1349px 477px #fff,
    715px 30px #fff, 1291px 1631px #fff, 1458px 865px #fff, 707px 1011px #fff,
    584px 646px #fff, 860px 577px #fff, 1154px 695px #fff, 162px 541px #fff,
    428px 846px #fff, 255px 143px #fff, 548px 496px #fff, 874px 1813px #fff,
    895px 885px #fff, 1328px 177px #fff, 1785px 1564px #fff, 144px 945px #fff,
    1050px 822px #fff, 1943px 846px #fff, 1690px 161px #fff, 1731px 302px #fff,
    455px 1263px #fff, 924px 1072px #fff, 1854px 559px #fff, 1824px 1989px #fff,
    629px 1273px #fff, 809px 1148px #fff, 66px 174px #fff, 446px 360px #fff,
    1459px 240px #fff, 623px 1530px #fff, 741px 1882px #fff, 467px 211px #fff,
    829px 395px #fff, 931px 62px #fff, 1846px 1435px #fff, 638px 1904px #fff,
    1460px 1114px #fff, 1984px 1710px #fff, 1114px 1879px #fff, 38px 1161px #fff,
    423px 603px #fff, 967px 1643px #fff, 1888px 603px #fff, 758px 1943px #fff,
    1580px 1274px #fff, 912px 957px #fff, 539px 962px #fff, 368px 1347px #fff,
    1409px 1580px #fff, 437px 1172px #fff, 1804px 755px #fff, 1529px 1068px #fff,
    25px 101px #fff, 1804px 445px #fff, 1357px 1754px #fff, 1619px 1272px #fff,
    372px 539px #fff, 1526px 505px #fff, 554px 522px #fff, 1005px 32px #fff,
    905px 474px #fff, 1223px 1808px #fff, 633px 569px #fff, 1959px 1716px #fff,
    494px 1323px #fff, 625px 1862px #fff, 1614px 1651px #fff, 1697px 1264px #fff,
    140px 565px #fff, 398px 81px #fff, 1066px 1971px #fff, 1007px 1000px #fff,
    1868px 477px #fff, 1012px 1843px #fff, 707px 331px #fff, 1563px 631px #fff,
    1122px 1578px #fff, 164px 73px #fff, 118px 477px #fff, 273px 1898px #fff,
    1247px 201px #fff, 473px 763px #fff, 574px 1601px #fff, 1589px 1877px #fff,
    655px 551px #fff, 924px 537px #fff, 1358px 273px #fff, 1302px 129px #fff,
    155px 1148px #fff, 664px 1944px #fff, 1773px 1888px #fff, 1816px 1727px #fff,
    1708px 1470px #fff, 114px 1558px #fff, 164px 577px #fff, 1109px 1287px #fff,
    1122px 1770px #fff, 852px 1901px #fff, 1532px 1349px #fff, 488px 72px #fff,
    529px 1182px #fff, 810px 79px #fff, 1800px 110px #fff, 440px 1684px #fff,
    1061px 1923px #fff, 1356px 449px #fff, 1002px 1824px #fff,
    1927px 1975px #fff, 893px 1536px #fff, 344px 902px #fff, 1108px 1781px #fff,
    50px 1560px #fff, 745px 399px #fff, 1347px 920px #fff, 891px 1725px #fff,
    825px 1219px #fff, 1085px 1095px #fff, 477px 51px #fff, 976px 1139px #fff,
    614px 1089px #fff, 1787px 327px #fff, 703px 53px #fff, 1375px 1861px #fff,
    1888px 260px #fff, 746px 819px #fff, 1230px 1365px #fff, 1987px 1535px #fff,
    107px 136px #fff, 500px 1804px #fff, 716px 1885px #fff, 1125px 980px #fff,
    1228px 1089px #fff, 1857px 1045px #fff, 191px 1898px #fff, 1703px 136px #fff,
    1317px 1024px #fff, 1502px 1683px #fff, 884px 531px #fff, 1283px 925px #fff,
    1733px 1673px #fff, 1471px 732px #fff, 392px 676px #fff, 1516px 1687px #fff,
    940px 888px #fff, 1568px 1204px #fff, 1556px 286px #fff, 1956px 1922px #fff,
    73px 1754px #fff, 1838px 1976px #fff, 1965px 1902px #fff, 321px 1754px #fff,
    1861px 1938px #fff, 831px 367px #fff, 1215px 992px #fff, 345px 1055px #fff,
    866px 1398px #fff, 1893px 883px #fff, 223px 1303px #fff, 298px 632px #fff,
    58px 892px #fff, 1460px 184px #fff, 1685px 1049px #fff, 1151px 882px #fff,
    901px 411px #fff, 1639px 674px #fff, 691px 893px #fff, 1991px 1191px #fff,
    1932px 1355px #fff, 1662px 802px #fff, 1992px 222px #fff, 1999px 1226px #fff,
    658px 1627px #fff, 966px 1666px #fff, 632px 65px #fff, 1386px 1736px #fff,
    197px 912px #fff, 607px 1554px #fff, 1281px 1987px #fff, 358px 903px #fff,
    92px 1172px #fff, 1489px 1219px #fff, 1499px 963px #fff, 1375px 1123px #fff,
    1253px 1306px #fff, 1427px 471px #fff, 350px 134px #fff, 370px 1456px #fff,
    1858px 1869px #fff, 1769px 539px #fff, 1154px 63px #fff, 1527px 689px #fff,
    1326px 1743px #fff, 340px 1535px #fff, 533px 683px #fff, 795px 1221px #fff,
    177px 1933px #fff, 856px 842px #fff, 1990px 850px #fff, 714px 1330px #fff,
    747px 1838px #fff, 4px 770px #fff, 873px 1196px #fff, 1084px 747px #fff,
    1567px 100px #fff, 722px 1890px #fff, 332px 1443px #fff, 1667px 994px #fff,
    453px 684px #fff, 1439px 66px #fff, 822px 680px #fff, 460px 1890px #fff,
    911px 1644px #fff, 69px 1360px #fff, 1465px 123px #fff, 285px 847px #fff,
    1311px 1886px #fff, 111px 14px #fff, 1432px 927px #fff, 795px 879px #fff,
    369px 1567px #fff, 1092px 809px #fff, 1470px 562px #fff, 713px 439px #fff,
    1028px 185px #fff, 1552px 329px #fff, 1346px 1114px #fff, 863px 1679px #fff,
    284px 1518px #fff, 463px 1475px #fff, 812px 1981px #fff, 1832px 1160px #fff,
    977px 1083px #fff, 837px 1388px #fff, 1367px 1518px #fff, 1953px 658px #fff,
    1361px 1699px #fff, 1595px 1782px #fff, 1002px 1645px #fff,
    1542px 1214px #fff, 1915px 516px #fff, 497px 404px #fff, 1405px 491px #fff,
    909px 1425px #fff, 1479px 1865px #fff, 176px 595px #fff, 977px 1067px #fff,
    1888px 930px #fff, 698px 411px #fff, 969px 1923px #fff, 368px 126px #fff,
    6px 839px #fff, 1390px 1814px #fff, 1303px 474px #fff, 1876px 959px #fff,
    1539px 1680px #fff, 1364px 1308px #fff, 1193px 738px #fff, 907px 1786px #fff,
    690px 1887px #fff, 1422px 1801px #fff, 737px 1982px #fff, 1692px 1582px #fff,
    448px 1640px #fff, 1716px 1572px #fff, 125px 1527px #fff, 414px 1908px #fff,
    242px 27px #fff, 936px 795px #fff, 1350px 1205px #fff, 1828px 1998px #fff,
    335px 115px #fff, 698px 82px #fff, 1442px 1994px #fff, 1596px 1329px #fff,
    485px 362px #fff, 676px 171px #fff, 1822px 45px #fff, 801px 1314px #fff,
    1295px 1724px #fff, 27px 975px #fff, 1179px 1850px #fff, 1342px 382px #fff,
    1120px 1080px #fff, 655px 537px #fff, 565px 1858px #fff, 839px 664px #fff,
    1214px 1777px #fff, 382px 795px #fff, 838px 1106px #fff, 1095px 939px #fff,
    1659px 169px #fff, 1988px 1596px #fff, 754px 1874px #fff, 314px 1180px #fff,
    1164px 140px #fff, 443px 907px #fff, 473px 1966px #fff, 652px 551px #fff,
    1671px 1932px #fff, 942px 969px #fff, 1520px 81px #fff, 1205px 538px #fff,
    1432px 1746px #fff, 961px 1583px #fff, 711px 1031px #fff, 195px 1787px #fff,
    1762px 254px #fff, 8px 911px #fff, 1497px 1719px #fff, 203px 880px #fff,
    234px 1568px #fff, 1935px 466px #fff, 1949px 410px #fff, 455px 194px #fff,
    1262px 682px #fff, 976px 1701px #fff, 7px 978px #fff, 1092px 950px #fff,
    1967px 425px #fff, 91px 1740px #fff, 544px 1914px #fff, 154px 1793px #fff,
    441px 1588px #fff, 1614px 988px #fff, 597px 904px #fff, 1949px 1911px #fff,
    1000px 1380px #fff, 518px 1673px #fff, 1656px 1734px #fff,
    1975px 1785px #fff, 315px 1808px #fff, 1124px 746px #fff, 934px 596px #fff,
    1249px 1325px #fff, 927px 1136px #fff, 1190px 426px #fff, 658px 528px #fff,
    953px 308px #fff, 376px 1230px #fff, 1303px 1369px #fff, 1064px 1112px #fff,
    448px 446px #fff, 1610px 741px #fff, 1946px 155px #fff, 122px 739px #fff,
    1126px 197px #fff, 742px 1994px #fff, 1215px 1971px #fff, 1152px 1081px #fff,
    214px 1154px #fff, 1453px 1600px #fff, 1618px 1770px #fff, 783px 1955px #fff,
    1737px 272px #fff, 391px 773px #fff, 150px 745px #fff, 1842px 482px #fff,
    24px 732px #fff, 390px 1519px #fff, 662px 1442px #fff, 1270px 8px #fff,
    1515px 289px #fff, 536px 873px #fff, 1918px 202px #fff, 1840px 1490px #fff,
    582px 838px #fff, 1676px 406px #fff, 1070px 952px #fff, 944px 1561px #fff,
    977px 612px #fff, 1655px 361px #fff, 1015px 988px #fff, 181px 41px #fff,
    279px 923px #fff, 828px 252px #fff, 1188px 489px #fff, 1892px 929px #fff,
    705px 1273px #fff, 1534px 832px #fff, 1957px 1631px #fff, 646px 973px #fff,
    172px 1653px #fff, 1039px 1830px #fff, 96px 1176px #fff, 521px 352px #fff,
    1441px 181px #fff, 248px 1605px #fff, 159px 554px #fff, 1240px 1718px #fff,
    408px 689px #fff, 1433px 404px #fff, 760px 184px #fff, 863px 178px #fff,
    1605px 273px #fff, 1980px 1040px #fff, 1414px 998px #fff, 962px 1593px #fff,
    1069px 1049px #fff, 851px 1766px #fff, 926px 1046px #fff, 906px 536px #fff,
    970px 1534px #fff, 505px 662px #fff, 188px 1041px #fff, 1066px 926px #fff,
    573px 45px #fff, 1419px 1005px #fff, 216px 1045px #fff, 1702px 549px #fff,
    1935px 1067px #fff, 347px 1494px #fff, 910px 575px #fff, 1641px 1255px #fff,
    579px 1091px #fff, 1151px 334px #fff, 1438px 1952px #fff, 312px 1255px #fff,
    306px 911px #fff, 1794px 276px #fff, 1928px 493px #fff, 895px 1363px #fff,
    1808px 1070px #fff, 580px 1736px #fff, 311px 1688px #fff, 1873px 945px #fff,
    967px 1382px #fff, 1732px 332px #fff, 227px 888px #fff, 521px 744px #fff,
    661px 1754px #fff, 1138px 1225px #fff, 1427px 1095px #fff, 1670px 816px #fff,
    278px 1641px #fff, 1081px 1002px #fff, 380px 1794px #fff, 67px 302px #fff,
    1920px 191px #fff, 176px 1074px #fff, 205px 1870px #fff, 1457px 1339px #fff,
    390px 783px #fff, 1881px 1541px #fff, 1162px 1709px #fff, 1200px 861px #fff,
    316px 1913px #fff, 252px 168px #fff, 908px 1210px #fff, 679px 315px #fff,
    800px 547px #fff, 1709px 1411px #fff, 1786px 166px #fff, 919px 213px #fff,
    127px 51px #fff, 324px 542px #fff, 1939px 1565px #fff, 1907px 1145px #fff,
    177px 794px #fff, 110px 1618px #fff, 483px 539px #fff, 1894px 438px #fff,
    1051px 986px #fff, 1973px 1150px #fff, 902px 1697px #fff, 1485px 1475px #fff,
    1907px 1579px #fff, 1642px 1146px #fff, 1098px 741px #fff, 681px 1709px #fff,
    618px 238px #fff, 1066px 1973px #fff, 478px 1675px #fff, 1603px 1661px #fff,
    103px 1133px #fff, 1034px 435px #fff, 393px 398px #fff, 1771px 1397px #fff,
    1738px 522px #fff, 1178px 1807px #fff, 38px 532px #fff, 652px 1537px #fff,
    1610px 1184px #fff, 1355px 98px #fff, 1779px 456px #fff, 1413px 572px #fff,
    1993px 109px #fff, 157px 515px #fff, 1451px 635px #fff, 1984px 1318px #fff,
    348px 256px #fff, 1216px 863px #fff, 1468px 135px #fff, 1130px 1119px #fff,
    1523px 51px #fff, 1984px 487px #fff, 718px 1722px #fff, 1166px 1379px #fff,
    1495px 1832px #fff, 699px 1301px #fff, 1020px 522px #fff, 1716px 1554px #fff,
    141px 500px #fff, 1369px 1176px #fff, 1373px 1431px #fff, 630px 1760px #fff,
    849px 45px #fff, 774px 857px #fff, 1780px 1798px #fff, 90px 1962px #fff,
    130px 368px #fff, 1380px 234px #fff, 1566px 1838px #fff, 969px 263px #fff,
    246px 26px #fff, 108px 697px #fff, 206px 1282px #fff, 236px 726px #fff,
    1700px 332px #fff, 342px 1121px #fff, 706px 1671px #fff, 1559px 854px #fff,
    604px 1467px #fff, 1937px 245px #fff, 1938px 1345px #fff, 1265px 273px #fff,
    766px 1411px #fff, 1543px 1511px #fff, 864px 750px #fff, 173px 1961px #fff,
    1085px 177px #fff, 1680px 68px #fff, 1563px 1800px #fff, 1509px 1214px #fff,
    1113px 1115px #fff, 1825px 564px #fff, 47px 1455px #fff, 1907px 447px #fff,
    659px 562px #fff, 991px 1944px #fff, 1829px 92px #fff, 1960px 591px #fff,
    1708px 1471px #fff, 780px 449px #fff, 602px 1651px #fff, 1154px 1082px #fff,
    1809px 479px #fff, 1833px 479px #fff, 12px 257px #fff, 446px 725px #fff,
    1174px 279px #fff, 1228px 309px #fff, 209px 234px #fff, 1364px 186px #fff,
    1816px 418px #fff, 1577px 883px #fff, 185px 987px #fff, 1045px 341px #fff,
    1884px 1774px #fff, 130px 50px #fff, 1415px 237px #fff, 1028px 1386px #fff,
    904px 1884px #fff, 1062px 113px #fff, 1850px 948px #fff, 311px 1107px #fff,
    84px 545px #fff, 1217px 220px #fff, 1579px 190px #fff, 1594px 1673px #fff,
    577px 17px #fff, 387px 1275px #fff, 281px 963px #fff, 1032px 1353px #fff,
    1075px 1247px #fff, 173px 931px #fff, 110px 949px #fff, 229px 882px #fff,
    349px 1040px #fff, 944px 1916px #fff, 1678px 1668px #fff, 741px 1778px #fff,
    1150px 432px #fff, 1496px 1920px #fff, 134px 1014px #fff, 1468px 994px #fff,
    1080px 597px #fff;
}

#stars2 {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 919px 693px #fff, 1846px 1966px #fff, 956px 1784px #fff,
    609px 136px #fff, 606px 134px #fff, 489px 669px #fff, 1679px 1055px #fff,
    1927px 791px #fff, 1718px 199px #fff, 1144px 1633px #fff, 391px 119px #fff,
    343px 1466px #fff, 1743px 1432px #fff, 21px 403px #fff, 1322px 1509px #fff,
    1586px 700px #fff, 965px 866px #fff, 1791px 1186px #fff, 1063px 701px #fff,
    551px 516px #fff, 7px 53px #fff, 191px 1668px #fff, 126px 851px #fff,
    1998px 942px #fff, 1405px 640px #fff, 945px 1092px #fff, 584px 200px #fff,
    301px 115px #fff, 1619px 452px #fff, 1838px 1258px #fff, 938px 1124px #fff,
    117px 480px #fff, 1918px 1580px #fff, 1933px 690px #fff, 1339px 133px #fff,
    177px 312px #fff, 1227px 429px #fff, 416px 1770px #fff, 286px 293px #fff,
    1015px 1029px #fff, 1585px 47px #fff, 1169px 662px #fff, 1643px 638px #fff,
    1576px 1275px #fff, 697px 388px #fff, 855px 1707px #fff, 1153px 1707px #fff,
    494px 308px #fff, 1597px 1482px #fff, 1503px 67px #fff, 1887px 481px #fff,
    1326px 1950px #fff, 838px 1494px #fff, 551px 939px #fff, 292px 30px #fff,
    1319px 961px #fff, 1594px 51px #fff, 864px 1404px #fff, 1981px 977px #fff,
    1240px 1335px #fff, 755px 851px #fff, 1703px 879px #fff, 1293px 1992px #fff,
    1401px 1877px #fff, 938px 1944px #fff, 1539px 919px #fff, 606px 1938px #fff,
    1933px 1846px #fff, 1056px 312px #fff, 1843px 1435px #fff,
    1580px 1013px #fff, 1448px 317px #fff, 1081px 123px #fff, 35px 1595px #fff,
    963px 1270px #fff, 926px 1664px #fff, 274px 1471px #fff, 1894px 859px #fff,
    960px 1337px #fff, 1083px 1294px #fff, 1153px 1789px #fff,
    1470px 1611px #fff, 116px 595px #fff, 616px 1248px #fff, 449px 1035px #fff,
    375px 535px #fff, 1960px 9px #fff, 1801px 324px #fff, 224px 1541px #fff,
    631px 1922px #fff, 1992px 1835px #fff, 644px 1087px #fff, 1175px 1221px #fff,
    1014px 573px #fff, 287px 1640px #fff, 875px 909px #fff, 1706px 1646px #fff,
    273px 1273px #fff, 1668px 869px #fff, 293px 744px #fff, 1391px 1688px #fff,
    1895px 165px #fff, 159px 1647px #fff, 1681px 143px #fff, 35px 721px #fff,
    822px 1668px #fff, 1008px 350px #fff, 1937px 1726px #fff, 348px 633px #fff,
    570px 1623px #fff, 1191px 1787px #fff, 1638px 1027px #fff, 1498px 747px #fff,
    1523px 566px #fff, 543px 1299px #fff, 1961px 541px #fff, 1166px 1472px #fff,
    765px 1846px #fff, 195px 1912px #fff, 313px 777px #fff, 1878px 639px #fff,
    1352px 353px #fff, 605px 1788px #fff, 1549px 1382px #fff, 629px 956px #fff,
    1088px 609px #fff, 1026px 434px #fff, 256px 1132px #fff, 1153px 140px #fff,
    1818px 1995px #fff, 1266px 60px #fff, 426px 72px #fff, 710px 1742px #fff,
    1355px 1018px #fff, 630px 1944px #fff, 533px 1487px #fff, 1327px 1217px #fff,
    1855px 1797px #fff, 175px 1243px #fff, 739px 1492px #fff, 1450px 1469px #fff,
    1527px 559px #fff, 1953px 1819px #fff, 943px 928px #fff, 408px 1005px #fff,
    1779px 1884px #fff, 717px 1470px #fff, 738px 1660px #fff, 589px 456px #fff,
    344px 276px #fff, 1784px 557px #fff, 1858px 1908px #fff, 1687px 1111px #fff,
    1225px 598px #fff, 1396px 1403px #fff, 1460px 460px #fff, 1401px 226px #fff,
    1213px 142px #fff, 414px 207px #fff, 1582px 315px #fff, 1702px 33px #fff,
    447px 1485px #fff, 247px 1833px #fff, 1802px 349px #fff, 1877px 1131px #fff,
    1735px 618px #fff, 1117px 188px #fff, 1014px 680px #fff, 166px 1912px #fff,
    303px 989px #fff, 736px 1099px #fff, 742px 418px #fff, 1896px 602px #fff,
    57px 491px #fff, 1881px 955px #fff, 514px 1080px #fff, 688px 674px #fff,
    1666px 69px #fff, 1732px 912px #fff, 334px 572px #fff, 1764px 1411px #fff,
    1585px 469px #fff, 1588px 1500px #fff, 1510px 287px #fff, 990px 1019px #fff,
    1974px 849px #fff, 1065px 35px #fff, 765px 516px #fff, 1889px 1707px #fff,
    1303px 589px #fff, 1881px 189px #fff, 790px 1026px #fff, 71px 1345px #fff,
    1421px 1319px #fff, 413px 116px #fff, 1036px 1552px #fff, 1453px 1319px #fff,
    1943px 1638px #fff, 1000px 546px #fff, 678px 1117px #fff;
  animation: animStar 100s linear infinite;
}
#stars2:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 919px 693px #fff, 1846px 1966px #fff, 956px 1784px #fff,
    609px 136px #fff, 606px 134px #fff, 489px 669px #fff, 1679px 1055px #fff,
    1927px 791px #fff, 1718px 199px #fff, 1144px 1633px #fff, 391px 119px #fff,
    343px 1466px #fff, 1743px 1432px #fff, 21px 403px #fff, 1322px 1509px #fff,
    1586px 700px #fff, 965px 866px #fff, 1791px 1186px #fff, 1063px 701px #fff,
    551px 516px #fff, 7px 53px #fff, 191px 1668px #fff, 126px 851px #fff,
    1998px 942px #fff, 1405px 640px #fff, 945px 1092px #fff, 584px 200px #fff,
    301px 115px #fff, 1619px 452px #fff, 1838px 1258px #fff, 938px 1124px #fff,
    117px 480px #fff, 1918px 1580px #fff, 1933px 690px #fff, 1339px 133px #fff,
    177px 312px #fff, 1227px 429px #fff, 416px 1770px #fff, 286px 293px #fff,
    1015px 1029px #fff, 1585px 47px #fff, 1169px 662px #fff, 1643px 638px #fff,
    1576px 1275px #fff, 697px 388px #fff, 855px 1707px #fff, 1153px 1707px #fff,
    494px 308px #fff, 1597px 1482px #fff, 1503px 67px #fff, 1887px 481px #fff,
    1326px 1950px #fff, 838px 1494px #fff, 551px 939px #fff, 292px 30px #fff,
    1319px 961px #fff, 1594px 51px #fff, 864px 1404px #fff, 1981px 977px #fff,
    1240px 1335px #fff, 755px 851px #fff, 1703px 879px #fff, 1293px 1992px #fff,
    1401px 1877px #fff, 938px 1944px #fff, 1539px 919px #fff, 606px 1938px #fff,
    1933px 1846px #fff, 1056px 312px #fff, 1843px 1435px #fff,
    1580px 1013px #fff, 1448px 317px #fff, 1081px 123px #fff, 35px 1595px #fff,
    963px 1270px #fff, 926px 1664px #fff, 274px 1471px #fff, 1894px 859px #fff,
    960px 1337px #fff, 1083px 1294px #fff, 1153px 1789px #fff,
    1470px 1611px #fff, 116px 595px #fff, 616px 1248px #fff, 449px 1035px #fff,
    375px 535px #fff, 1960px 9px #fff, 1801px 324px #fff, 224px 1541px #fff,
    631px 1922px #fff, 1992px 1835px #fff, 644px 1087px #fff, 1175px 1221px #fff,
    1014px 573px #fff, 287px 1640px #fff, 875px 909px #fff, 1706px 1646px #fff,
    273px 1273px #fff, 1668px 869px #fff, 293px 744px #fff, 1391px 1688px #fff,
    1895px 165px #fff, 159px 1647px #fff, 1681px 143px #fff, 35px 721px #fff,
    822px 1668px #fff, 1008px 350px #fff, 1937px 1726px #fff, 348px 633px #fff,
    570px 1623px #fff, 1191px 1787px #fff, 1638px 1027px #fff, 1498px 747px #fff,
    1523px 566px #fff, 543px 1299px #fff, 1961px 541px #fff, 1166px 1472px #fff,
    765px 1846px #fff, 195px 1912px #fff, 313px 777px #fff, 1878px 639px #fff,
    1352px 353px #fff, 605px 1788px #fff, 1549px 1382px #fff, 629px 956px #fff,
    1088px 609px #fff, 1026px 434px #fff, 256px 1132px #fff, 1153px 140px #fff,
    1818px 1995px #fff, 1266px 60px #fff, 426px 72px #fff, 710px 1742px #fff,
    1355px 1018px #fff, 630px 1944px #fff, 533px 1487px #fff, 1327px 1217px #fff,
    1855px 1797px #fff, 175px 1243px #fff, 739px 1492px #fff, 1450px 1469px #fff,
    1527px 559px #fff, 1953px 1819px #fff, 943px 928px #fff, 408px 1005px #fff,
    1779px 1884px #fff, 717px 1470px #fff, 738px 1660px #fff, 589px 456px #fff,
    344px 276px #fff, 1784px 557px #fff, 1858px 1908px #fff, 1687px 1111px #fff,
    1225px 598px #fff, 1396px 1403px #fff, 1460px 460px #fff, 1401px 226px #fff,
    1213px 142px #fff, 414px 207px #fff, 1582px 315px #fff, 1702px 33px #fff,
    447px 1485px #fff, 247px 1833px #fff, 1802px 349px #fff, 1877px 1131px #fff,
    1735px 618px #fff, 1117px 188px #fff, 1014px 680px #fff, 166px 1912px #fff,
    303px 989px #fff, 736px 1099px #fff, 742px 418px #fff, 1896px 602px #fff,
    57px 491px #fff, 1881px 955px #fff, 514px 1080px #fff, 688px 674px #fff,
    1666px 69px #fff, 1732px 912px #fff, 334px 572px #fff, 1764px 1411px #fff,
    1585px 469px #fff, 1588px 1500px #fff, 1510px 287px #fff, 990px 1019px #fff,
    1974px 849px #fff, 1065px 35px #fff, 765px 516px #fff, 1889px 1707px #fff,
    1303px 589px #fff, 1881px 189px #fff, 790px 1026px #fff, 71px 1345px #fff,
    1421px 1319px #fff, 413px 116px #fff, 1036px 1552px #fff, 1453px 1319px #fff,
    1943px 1638px #fff, 1000px 546px #fff, 678px 1117px #fff;
}

#stars3 {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 1002px 1571px #fff, 1186px 730px #fff, 934px 1619px #fff,
    590px 1529px #fff, 1464px 1993px #fff, 1171px 1768px #fff,
    1454px 1041px #fff, 585px 2px #fff, 1164px 402px #fff, 1837px 379px #fff,
    1564px 643px #fff, 1522px 610px #fff, 1216px 574px #fff, 552px 246px #fff,
    1376px 1254px #fff, 1900px 1611px #fff, 1059px 1430px #fff,
    1885px 667px #fff, 297px 1591px #fff, 188px 121px #fff, 900px 352px #fff,
    666px 1353px #fff, 1402px 1800px #fff, 1858px 1047px #fff,
    1742px 1153px #fff, 843px 1630px #fff, 1101px 1521px #fff, 201px 1526px #fff,
    770px 1237px #fff, 1596px 1160px #fff, 1430px 765px #fff, 837px 1380px #fff,
    1638px 855px #fff, 498px 1737px #fff, 409px 1030px #fff, 917px 982px #fff,
    1934px 1198px #fff, 54px 864px #fff, 1618px 1049px #fff, 1980px 1145px #fff,
    83px 1303px #fff, 1020px 151px #fff, 522px 1729px #fff, 809px 1563px #fff,
    1709px 991px #fff, 820px 11px #fff, 1928px 1734px #fff, 564px 1841px #fff,
    1112px 1067px #fff, 1002px 1180px #fff, 854px 1186px #fff, 242px 1734px #fff,
    1207px 1287px #fff, 1635px 889px #fff, 669px 1149px #fff, 833px 961px #fff,
    464px 1662px #fff, 271px 344px #fff, 1003px 752px #fff, 104px 1093px #fff,
    1929px 83px #fff, 169px 1343px #fff, 1392px 137px #fff, 374px 591px #fff,
    384px 960px #fff, 165px 1819px #fff, 766px 268px #fff, 1385px 132px #fff,
    1018px 1739px #fff, 1694px 1681px #fff, 1589px 1806px #fff, 377px 108px #fff,
    1655px 726px #fff, 1056px 699px #fff, 785px 1136px #fff, 1087px 761px #fff,
    1420px 96px #fff, 1572px 589px #fff, 1858px 1401px #fff, 1045px 1304px #fff,
    856px 711px #fff, 1012px 1826px #fff, 1757px 1512px #fff, 859px 844px #fff,
    497px 1944px #fff, 14px 1505px #fff, 762px 673px #fff, 688px 1863px #fff,
    1713px 345px #fff, 852px 999px #fff, 647px 257px #fff, 1008px 534px #fff,
    1689px 336px #fff, 1482px 1675px #fff, 867px 969px #fff, 859px 563px #fff,
    1463px 1637px #fff, 1461px 192px #fff, 1071px 1621px #fff,
    1155px 1050px #fff;
  animation: animStar 150s linear infinite;
}
#stars3:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 1002px 1571px #fff, 1186px 730px #fff, 934px 1619px #fff,
    590px 1529px #fff, 1464px 1993px #fff, 1171px 1768px #fff,
    1454px 1041px #fff, 585px 2px #fff, 1164px 402px #fff, 1837px 379px #fff,
    1564px 643px #fff, 1522px 610px #fff, 1216px 574px #fff, 552px 246px #fff,
    1376px 1254px #fff, 1900px 1611px #fff, 1059px 1430px #fff,
    1885px 667px #fff, 297px 1591px #fff, 188px 121px #fff, 900px 352px #fff,
    666px 1353px #fff, 1402px 1800px #fff, 1858px 1047px #fff,
    1742px 1153px #fff, 843px 1630px #fff, 1101px 1521px #fff, 201px 1526px #fff,
    770px 1237px #fff, 1596px 1160px #fff, 1430px 765px #fff, 837px 1380px #fff,
    1638px 855px #fff, 498px 1737px #fff, 409px 1030px #fff, 917px 982px #fff,
    1934px 1198px #fff, 54px 864px #fff, 1618px 1049px #fff, 1980px 1145px #fff,
    83px 1303px #fff, 1020px 151px #fff, 522px 1729px #fff, 809px 1563px #fff,
    1709px 991px #fff, 820px 11px #fff, 1928px 1734px #fff, 564px 1841px #fff,
    1112px 1067px #fff, 1002px 1180px #fff, 854px 1186px #fff, 242px 1734px #fff,
    1207px 1287px #fff, 1635px 889px #fff, 669px 1149px #fff, 833px 961px #fff,
    464px 1662px #fff, 271px 344px #fff, 1003px 752px #fff, 104px 1093px #fff,
    1929px 83px #fff, 169px 1343px #fff, 1392px 137px #fff, 374px 591px #fff,
    384px 960px #fff, 165px 1819px #fff, 766px 268px #fff, 1385px 132px #fff,
    1018px 1739px #fff, 1694px 1681px #fff, 1589px 1806px #fff, 377px 108px #fff,
    1655px 726px #fff, 1056px 699px #fff, 785px 1136px #fff, 1087px 761px #fff,
    1420px 96px #fff, 1572px 589px #fff, 1858px 1401px #fff, 1045px 1304px #fff,
    856px 711px #fff, 1012px 1826px #fff, 1757px 1512px #fff, 859px 844px #fff,
    497px 1944px #fff, 14px 1505px #fff, 762px 673px #fff, 688px 1863px #fff,
    1713px 345px #fff, 852px 999px #fff, 647px 257px #fff, 1008px 534px #fff,
    1689px 336px #fff, 1482px 1675px #fff, 867px 969px #fff, 859px 563px #fff,
    1463px 1637px #fff, 1461px 192px #fff, 1071px 1621px #fff,
    1155px 1050px #fff;
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
}
