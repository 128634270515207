#learn-more h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 52px;
  color: rgba(237, 57, 123, 1);
  margin-bottom: 40px;
}
#learn-more h2 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: #ed397b;
  margin-bottom: 40px;
}
#learn-more button {
  width: 332px;
  height: 64px;

  background: #ffebeb;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;

  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 35px;
  color: #ed397b;
}
@media (min-device-width: 280px) and (max-device-width: 768px) {
  #learn-more h1 {
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
  }
  #learn-more h2 {
    font-size: 16px;
    line-height: 23px;
  }
  #learn-more button {
    width: 167px;
    height: 40px;

    font-size: 18px;
    line-height: 26px;
  }
}
@media (min-width: 768px) and (max-width: 1180px) {
  #learn-more h1 {
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
  }
  #learn-more h2 {
    font-size: 18px;
    line-height: 26px;
  }
  #learn-more button {
    width: 228px;
    height: 46px;

    font-size: 20px;
    line-height: 29px;
  }

}