#company-address {
  margin-top: 20px;
}
#company-address .map-cover {
  height: 610px;
}
#company-address .map-cover iframe {
  border: 0;
  width: 100%;
  height: 100%;
}
#company-address .company-address-form {
  padding: 20px;
}
#company-address .company-address-info {
  background: #e5e5e5;
  padding: 40px;
}
#company-address .company-address-form .form-group {
  margin-bottom: 10px;
}
#company-address .company-address-form input {
  outline: 0;
  border-width: 0 0 1px;
  border-color: 0e3150;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  /* or 19px */

  color: #282e2f;
}
#company-address .btn-company-address-submit {
  border: 1px solid #282e2f;
  box-sizing: border-box;
  width: 144px;

  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height */

  text-align: center;

  color: #282e2f;
}
#company-address .company-address-info .info-head {
  margin-bottom: 30px;
}
#company-address .company-address-info-desc {
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height */

  color: #282e2f;
}
#company-address .company-address-info i {
  margin-right: 10px;
}

#company-address .company-address-info .info-head h1 {
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 52px;
  /* identical to box height */

  color: #282e2f;
}
#company-address .company-address-info .info-head p {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  /* identical to box height */

  color: #282e2f;
}
@media (min-device-width: 280px) and (max-device-width: 768px) {
  #company-address .map-cover {
    height: 253px;
  }
}
@media (min-width: 768px) and (max-width: 1180px) {
  #company-address .map-cover {
    height: 411px;
  }
}
